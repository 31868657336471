body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, "Lato", sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

:root {
    --background: hsla(205, 2%, 98%, 1);
    --card: hsla(205, 25%, 95%, 1);
    --description: hsla(205, 25%, 95%, 0.9);

    --title: hsla(205, 70%, 95%, 1);
    --text: hsla(205, 70%, 25%, 1);
    --text-hover: hsla(205, 70%, 35%, 1);
    --text-clicked: hsla(205, 70%, 45%, 1);
    --text-clicked-light: hsla(205, 70%, 60%, 1);
    --link: hsla(40, 75%, 50%, 1);
    --link-hover: hsla(45, 95%, 55%, 1);
    --shadow: hsla(205, 25%, 25%, 0.5);
}