@import "../../../breakpoints";

.testimonials {
    width: 100%;
    min-height: 100vh;
    position: relative;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url("../../../assets/img/background-testimonials.jpg");
        background-size: cover;
        background-position: bottom;
        // filter: blur(1px) opacity(0.8);
        filter: opacity(0.7);
        z-index: -1;
    }

    &__container {
        width: 80%;
        display: flex;
        flex-direction: column;
        align-items: center;
        height: 100%;
        line-height: 1.5;
        margin: 0 auto;

        @include breakpoint(md) {
            width: 90%;
        }
    }

    &__title {
        margin-top: 3vh;
        color: var(--text);
        font-size: 2rem;
        font-weight: 700;
        text-align: center;
        text-transform: uppercase;
        letter-spacing: 0.2rem;

        &::after {
            content: "";
            display: block;
            width: 5rem;
            height: 0.2rem;
            background-color: var(--text);
            margin: 1rem auto 0 auto;
        }
    }

    &__cardlist {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: space-between;
        margin-bottom: 3vh;
    }

    &__card {
        width: 40%;
        margin: 2rem;
        padding: 0.5rem 1rem 0.5rem 1rem;
        background-color: var(--background);
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        flex-wrap: wrap;
        border-radius: 0.5rem;
        height: 15rem;
        box-shadow: 0 0 0.5rem 0.1rem rgba(0, 0, 0, 0.2);

        @include breakpoint(xl) {
            width: 25%;
        }
        @include breakpoint(md) {
            width: calc(50% - 4rem);
            margin: 2rem 0;
        }
        @include breakpoint(xs) {
            width: 100%;
            margin: 2rem 0;
        }

        &__header {

            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;

            &__name {
                height: 2rem;
                display: flex;
                align-items: center;
                color: var(--text);
                font-size: 1.3rem;
                margin: 0.5rem;
            }

            &__stars {
                display: flex;
                height: 2rem;
                align-items: center;
                color: var(--text);
                margin: 0.5rem;
                color: var(--link);
                font-size: 1.3rem;
            }
        }



        &__comment {
            color: var(--text);
            font-size: 1rem;
            margin: 0.5rem;
            flex: 1;
            overflow-y: auto;
        }
    }

    &__button {
        margin: 2rem 0 2rem;
    }
}