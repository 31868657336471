@import "../../../breakpoints";

.pipe {
    &::before {
        content: "|";
        color: var(--text);

        @include breakpoint(sm) {
            content: "\A";
            white-space: pre;
        }
    }

}

.footer {
    width: 100%;
    min-height: 20vh;
    background: var(--background);
    padding: 1.5rem 0;

    &__container {
        display: flex;
        justify-content: space-around;
        flex-direction: row;

        @include breakpoint(lg) {
            flex-direction: column;
        }

        &>div {
            width: 33%;

            @include breakpoint(lg) {
                width: 100%;
                margin: 1rem 0;
            }

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: flex-start;
        }

        &__left {
            display: flex;
            flex-direction: column;
            align-items: center;

            &__text {
                display: flex;
                flex-direction: column;
                align-items: center;

                &__title {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: var(--text);
                }

                &__subtitle {
                    font-size: 1rem;
                    font-weight: 200;
                    color: var(--text);
                }
            }
        }

        &__middle {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            &__title {
                font-size: 1.5rem;
                font-weight: bold;
                color: var(--text);
                margin-bottom: 1rem;
                width: 100%;
                text-align: center;
            }

            &__links {
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-start;

                &__link {
                    text-decoration: none;
                    color: var(--link);
                    font-size: 1rem;
                    font-weight: 200;
                    margin: 0.2rem 0;
                    transition: 0.3s ease all;

                    &:hover {
                        color: var(--text-hover);
                    }
                }
            }
        }

        &__right {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            color: var(--text);

            &__title {
                font-size: 1.5rem;
                font-weight: bold;
                margin-bottom: 1rem;
                width: 100%;
                text-align: center;
            }

            &__contact {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
            }

            &__contact-item {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: flex-start;
                margin: 0.2rem 0;
                font-size: 1rem;
            }
        }
    }

    &__bottom {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
        margin-top: 3rem;

        &__text {
            font-size: 1rem;
            font-weight: 200;
            color: var(--text);
            padding: 0 2rem;
            text-align: center;

            &__link {
                text-decoration: none;
                color: var(--text);
                font-weight: bold;
                transition: 0.3s ease all;

                &:hover {
                    color: var(--text-hover);
                }
            }

            &__icon {
                color: red
            }
        }
    }
}