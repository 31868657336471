@import "../../../breakpoints";

.home {
    width: 100%;
    height: 100vh;

    position: relative;

    &__background {
        transform: rotate(180deg);
        background-image: url("../../../assets/img/background-home.jpg");
        background-size: cover;
        background-position: center;
        width: 100%;
        height: 100%;
        filter: blur(2px) opacity(0.5);
    }

    &__content {
        position: absolute;
        top: 0;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        line-height: 1.5;

        &__text {
            color: var(--text);
            margin: 0;
            text-align: center;
        }

        &__title {
            font-size: 3rem;
            line-height: 1;
        }

        &__subtitle {
            font-size: 1.5rem;
        }

        &__target {
            font-size: 1rem;
        }
    }
    &__button {
        margin-top: 2rem;
        @include breakpoint(sm) {
            margin-top: 10rem;
        }
    }
}