@import "../../../breakpoints";

.contact {
    width: 100%;
    margin-top: 3vh;
    margin-bottom: 10vh;

    &__container {
        display: flex;
        flex-direction: column;

        &__title {
            margin-top: 3vh;
            color: var(--text);
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.2rem;

            &::after {
                content: "";
                display: block;
                width: 5rem;
                height: 0.2rem;
                background-color: var(--text);
                margin: 1rem auto 0 auto;
            }
        }

        &__map {
            width: 80%;
            height: 50vh;
            margin: 5vh auto 0;
            border-radius: 7px;
            overflow: hidden;

            @include breakpoint(md) {
                width: 90%;
                margin: 5vh auto 0;
            }

            &__img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border: 0;
            }

            &__box {
                position: relative;
                margin-top: -30vh;
                margin-left: 20%;
                width: 20%;
                background-color: var(--card);
                border-radius: 7px;
                padding: 1rem 2vw 2vw;
                display: flex;
                flex-direction: column;
                border: 1px solid var(--text);

                @include breakpoint(sm) {
                    display: none;
                }

                &__title {
                    color: var(--text);
                    font-size: 1.3rem;
                    font-weight: 700;
                    text-align: center;
                }

                &__data {
                    margin-top: 0.5rem;
                    color: var(--text);
                    display: flex;
                    flex-direction: column;

                    &__table {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &__col-addr {
                            margin-right: 0.5rem;

                            &:last-child {
                                margin-right: 0;
                            }
                        }

                        &__col-time {
                            &:first-child {
                                width: 6.5rem;
                            }
                        }
                    }

                    &__text {
                        margin-top: 0.5rem;
                        font-size: 1rem;
                        text-align: justify;

                        &.bold {
                            font-weight: 700;
                        }

                        @include breakpoint(sm) {
                            font-size: 1.1rem;
                        }
                    }

                    &__button {
                        margin-top: 1rem;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 2rem;
                    }
                }
            }
        }

        &__content {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin: 5vh 10% 0;

            @include breakpoint(md) {
                flex-direction: column;
                width: 90%;
                margin: 5vh auto 0;
            }

            &__box {
                width: 30%;
                background-color: var(--card);
                border-radius: 7px;
                padding: 1rem 2vw 2vw;
                display: flex;
                flex-direction: column;

                &:first-child {
                    display: none;
                }

                @include breakpoint(sm) {
                    &:first-child {
                        display: flex;
                    }
                }

                @include breakpoint(md) {
                    width: 96%;
                    margin: 2rem auto 0;

                    &:first-child {
                        margin-top: 0;
                    }
                    padding: 1.5rem 2vw;
                }

                &__title {
                    color: var(--text);
                    font-size: 1.7rem;
                    font-weight: 700;
                    text-align: center;
                }

                &__data {
                    margin-top: 1rem;
                    color: var(--text);
                    display: flex;
                    flex-direction: column;

                    &__table {
                        display: flex;
                        flex-direction: row;
                        align-items: center;

                        &__col-addr {
                            margin-right: 0.5rem;

                            &:last-child {
                                margin-right: 0;
                            }
                        }

                        &__col-time {
                            &:first-child {
                                width: 6.5rem;
                            }
                        }
                        &__cab {
                            display: flex;
                            flex-direction: row;
                            align-items: center;
                            justify-content: space-around;
                            &-item {
                                display: flex;
                                flex-direction: column;
                            }

                            @include breakpoint(xs) {
                                flex-direction: column;
                                align-items: center;
                                &-item {
                                    margin-top: 1rem;
                                }
                            }
                        }
                    }

                    &__text {
                        margin-top: 0.5rem;
                        font-size: 1rem;
                        text-align: justify;

                        &.bold {
                            font-weight: 700;
                        }

                        &.underline {
                            text-decoration: underline;
                        }

                        &.centred {
                            text-align: center;
                        }

                        &.mt2 {
                            margin-top: 2rem;
                        }

                        @include breakpoint(sm) {
                            font-size: 1.1rem;
                        }
                    }

                    &__button {
                        margin-top: 1rem;
                        width: 80%;
                        margin-left: auto;
                        margin-right: auto;
                        margin-top: 2rem;
                    }
                }
            }
        }
    }
}