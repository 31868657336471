@import "../../../breakpoints";

.chiropractics {
    width: 100%;
    height: 100vh;
    position: relative;
    padding-bottom: 3rem;

    &::before {
        content: '';
        position: absolute;
        top: -1.5rem;
        left: 0;
        width: 100%;
        height: calc(100% + 1.5rem);
        background-image: url("../../../assets/img/background-chiropractics.jpg");
        background-size: cover;
        background-repeat: no-repeat;
        background-position: bottom;
        // filter: blur(2px) opacity(0.5);
        filter: opacity(0.7);
        z-index: -2;
    }

    &__container {
        &__title {
            margin-top: 2vh;
            padding-top: 3vh;
            color: var(--text);
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.2rem;

            &::after {
                content: "";
                display: block;
                width: 5rem;
                height: 0.2rem;
                background-color: var(--text);
                margin: 1rem auto 3rem auto;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;
            width: 80%;
            margin: 1rem auto;
            padding: 1rem;
            background-color: rgba(255, 255, 255, 0.7);
            border-radius: 7px;
            
            &__menu {
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: space-around;
    
                @include breakpoint(sm) {
                    flex-direction: column;
                }
    
                &__item {
                    font-size: 1.5rem;
                    font-weight: bold;
                    color: var(--text);
                    margin-top: 1rem;
                    cursor: pointer;
    
                    @include breakpoint(sm) {
                        margin-top: 0;
    
                        &>h3 {
                            margin: 0.5rem 0;
                        }
                    }
    
                    &.active {
                        color: var(--text-hover);
                        text-decoration: underline;
                    }
    
                    &:hover {
                        color: var(--text-hover);
                        cursor: pointer;
                    }
                }
            }

            &__data {
                display: flex;
                flex-direction: column;
                height: 60vh;
                overflow-y: auto;

                &__text {
                    font-size: 1.2rem;
                    color: var(--text);
                    padding: 1rem;
                    text-align: justify;
                    @include breakpoint(sm) {
                        padding: 0;
                        margin-bottom: 0;
                    }
                }

                &__image {
                    width: 50%;
                    aspect-ratio: 16 / 9;
                    margin: 1rem 0;
                    object-fit: cover;
                    display: flex;
                    margin: 0 auto;
                }
                &__legend {
                    margin-top: 2rem;
                    display: flex;
                    flex-direction: column;

                    &__item {
                        color: var(--text);
                    }
                }
            }
        }
    }
}