@mixin breakpoint($point) {
    @if $point == lg {
        @media (min-width: 1024px) {
            @content;
        }
    }
}

.bold {
    font-weight: bold;
}

.yellow {
    color: var(--link) !important;
}

.flag {
    width: 30px;
    height: 20px;
}

.navbar {
    background: var(--background);
    width: calc(100% - 3rem);
    height: 100px;
    padding: 0 1.5rem;
    transition: 0.3s ease all;
    overflow: hidden;
    position: fixed;
    z-index: 100;
    &__content {
        overflow: hidden;
        color: var(--text);
        margin: 0 auto;
        max-width: 1920px;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        z-index: 100;

        &__logo {
            display: flex;
            align-items: center;
            text-decoration: none;
            cursor: pointer;
            z-index: 101;
            &__img {
                width: 60px;
                height: 60px;
                margin-right: 1rem;
            }
            &__text {
                display: grid;
            }
            &__title {
                font-size: 1.75rem;
                font-weight: bold;
                text-decoration: none;
                color: var(--text);
            }
            &__subtitle {
                font-size: 1rem;
                font-weight: 200;
                color: var(--text);
            }
        }

        &__nav {
            top: 0;
            right: 100%;
            bottom: 0;
            width: 100%;
            height: fit-content;
            height: 100vh;
            position: fixed;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            text-align: center;
            background: var(--background);
            backdrop-filter: blur(2px);
            transform: translate(0);
            transition: 0.3s ease transform;

            @include breakpoint(lg) {
                transform: none;
                flex-direction: row;
                background: transparent;
                width: auto;
                height: 100%;
                position: static;
            }

            &__list {
                list-style: none;
                padding: 0;
                display: flex;
                flex-direction: column;
                margin-bottom: 32px;

                @include breakpoint(lg) {
                    flex-direction: row;
                    align-items: center;
                    margin-bottom: revert;
                    margin-right: calc(0.5rem + #{16px});
                }

                &__item {
                    &:not(:last-child) {
                        margin-bottom: 32px;

                        @include breakpoint(lg) {
                            margin-bottom: 0;
                            margin-right: 16px;
                        }
                    }

                    a {
                        text-decoration: none;
                        color: inherit;
                        padding: 0.75rem 0.75rem;
                        transition: 0.3s ease all;
                        font-size: 1.25rem;
                        cursor: pointer;
                    }
                }
            }

            &.isMenu {
                transform: translate(100%);
                ul {
                    li {
                        a {
                            font-size: 2rem;
                        }
                    }
                }
            }
        }

        &__toggle {
            cursor: pointer;
            display: flex;
            align-items: center;
            font-size: 2rem;
            transition: 0.3s ease all;
            position: relative;

            &:hover {
                color: var(--text-hover);
            }

            @include breakpoint(lg) {
                display: none;
            }
        }
    }
}