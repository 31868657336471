.AdminHome {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;

    &__title {
        font-size: 3rem;
        color: var(--link)
    }
}