@mixin breakpoint($point) {
    @if ($point == xs) {
        @media (max-width: 480px) {
            @content;
        }
    }

    @if ($point == sm) {
        @media (max-width: 768px) {
            @content;
        }
    }

    @if ($point == md) {
        @media (max-width: 1024px) {
            @content;
        }
    }

    @if ($point == lg) {
        @media (max-width: 1200px) {
            @content;
        }
    }

    @if ($point == xl) {
        @media (min-width: 1201px) {
            @content;
        }
    }
}