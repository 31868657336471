.login-form {
    text-align: center;
    max-width: 400px;
    padding: 20px;
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 4px 8px var(--link);
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    h1 {
        color: var(--link);
    }

    form {
        margin-top: 20px;

        label {
            display: block;
            margin-bottom: 8px;
            color: var(--link);
        }

        input[type="text"],
        input[type="password"] {
            width: calc(100% - 20px);
            padding: 10px;
            margin-bottom: 20px;
            border: 1px solid #ccc;
            border-radius: 5px;
            transition: border-color 0.3s;
        }

        button[type="submit"] {
            background-color: var(--link);
            color: #fff;
            padding: 10px 20px;
            border: none;
            border-radius: 7px;
            cursor: pointer;
            transition: background-color 0.3s;

            &:hover {
                background-color: var(--link-hover)
            }
        }
    }
}