@import "../../../breakpoints";

.about {
    min-height: 100vh;
    padding: 0 0 3rem;
    align-items: center;
    width: 100%;

    &__container {
        display: flex;
        flex-direction: column;

        &__title {
            margin-top: 3vh;
            color: var(--text);
            font-size: 2rem;
            font-weight: 700;
            text-align: center;
            text-transform: uppercase;
            letter-spacing: 0.2rem;

            &::after {
                content: "";
                display: block;
                width: 5rem;
                height: 0.2rem;
                background-color: var(--text);
                margin: 1rem auto 0 auto;
            }
        }

        &__content {
            margin-top: 10vh;
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            @include breakpoint(sm) {
                flex-direction: column;
            }

            &__box {

                position: relative;
                padding: 1rem;
                width: 20rem;
                height: 30rem;
                background-color: #f5f5f5;
                border-radius: 7px;
                background-size: cover;
                overflow: hidden;

                @include breakpoint(lg) {
                    width: 15rem;
                }

                @include breakpoint(md) {
                    width: 30%;
                }

                @include breakpoint(sm) {
                    width: calc(90% - 2rem);
                    padding: 1rem;
                    margin: 1rem auto;
                    height: auto;
                }


                &#about_profil {
                    background-image: url("../../../assets/img/clement-de-villepin-profile.jpg");
                }

                &#about_office {
                    background-image: url("../../../assets/img/chiropractor-office.jpg");
                }

                &__data {
                    display: flex;
                    flex-direction: column;
                    background-color: rgba(255, 255, 255, 0.8);
                    box-shadow: 0 0 2rem 3rem rgba(255, 255, 255, 0.8);
                    position: relative;
                    top: calc(100% - 2vh - 2.7rem);
                    transition: all ease-out 0.5s;
                    height: 100%;

                    @include breakpoint(sm) {
                        top: 0;
                    }

                    &__arrow {
                        position: absolute;
                        top: 1vh;
                        right: 0;
                        color: var(--link);
                        font-size: 2rem;
                        rotate: 180deg;
                        transition: all ease-out 0.5s;
                        cursor: pointer;

                        .deployed & {
                            rotate: 0deg;
                        }

                        @include breakpoint(sm) {
                            display: none;
                        }
                    }

                    &__title {
                        margin-top: 2vh;
                        color: var(--text);
                        font-size: 1.5rem;
                        font-weight: 700;
                    }

                    &__subtitle {
                        color: var(--text);
                        font-size: 1rem;
                    }

                    &__text {
                        margin-top: 2vh;
                        color: var(--text);
                        font-size: 1rem;
                        font-weight: 400;
                        line-height: 1.2rem;
                        text-align: justify;

                        &>br {
                            margin: 10px;
                        }
                    }

                    &.deployed {
                        top: 0;
                    }
                }
            }
        }
    }
}