.button {
    appearance: none;
    background-color: transparent;
    border: 2px solid var(--text);
    border-radius: 7px;
    box-sizing: border-box;
    color: var(--text);
    cursor: pointer;
    display: inline-block;
    font-size: 16px;
    font-weight: 600;
    min-height: 3rem;
    min-width: 10rem;
    outline: none;
    padding: 1rem 2rem;
    text-align: center;
    text-decoration: none;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    will-change: transform;

    &:hover {
        background-color: var(--text-hover);
        border-color: var(--text-hover);
        color: #fff;
    }

    &:active {
        background-color: var(--text-clicked);
        border-color: var(--text-clicked);
        color: #fff;
    }

    &.colored {
        background-color: var(--text-hover);
        border-color: var(--text-hover);
        color: #fff;

        &:hover {
            background-color: var(--text-clicked);
            border-color: var(--text-hover);
        }

        &:active {
            background-color: var(--text-clicked);
            border-color: var(--text-clicked);
        }
    }
}